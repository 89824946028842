import * as HxAuthServiceClient from './service_client/HxAuthServiceClient.js'
import sha256 from 'crypto-js/sha256.js';
import hmacSHA256 from 'crypto-js/hmac-sha256.js';

var DEV_ENV = (["localhost", "127.0.0.1"].includes(window.location.host.substring(0, window.location.host.indexOf(":"))) || window.location.host == "localhost")

/**
 * Validate session
 * @returns Promise
 */
export function validateSession(urlOrigin) {
  if (DEV_ENV) return new Promise((resolve) => resolve())

  // Check if jwt cookie is present
  let jwt = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1]
  if (jwt === undefined || jwt === "") return Promise.reject()
  // Validate cookie if present
  return HxAuthServiceClient.validate(urlOrigin)
}

/**
 *
 * @returns {{}} JWT Payload or null if no JWT present
 */
export function getJwtPayload() {
  let jwt = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1]
  if (jwt === undefined || jwt === "") return null;
  return JSON.parse(atob(jwt.split('.')?.[1]));
}

/**
 *
 * @returns {int?} Authenticated privilege level or null if no JWT present
 */
export function getJwtPrivilege() {
  return getJwtPayload()?.privilege;
}

/**
 * Login
 * @returns Promise
 */
export function login(urlOrigin, username, password) {
  return HxAuthServiceClient.login1(urlOrigin, username).then(response => {

    let hashDigest = sha256(`${password}${response.salt}`).toString()
    let hmac = hmacSHA256(response.challenge, hashDigest).toString()

    return HxAuthServiceClient.login2(urlOrigin, username, hmac)
  })
}

/**
 * Logout
 * @returns Promise
 */
export function invalidateSession(urlOrigin) {
  return HxAuthServiceClient.invalidate(urlOrigin)
}
