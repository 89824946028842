import './App.css';
import React from 'react';
import Header from './Header';
import Content from './content/Content';
import Login from './login/Login'
import Config from '../config.js';
import { validateSession, getJwtPrivilege, getJwtPayload } from '@harxer/session-manager-lib';
import { CONTENT_DISPLAYS as SectionNames } from '../javascript/constants';

class App extends React.Component {
  constructor(props) {
    super(props)

    this.handleSectionSelected = this.handleSectionSelected.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      loggedIn: false,
      sectionSelected: SectionNames.LOGS,
      userPrivilege: undefined
    }
  }

  componentDidMount() {
    validateSession(Config.url.auth).then(_ => {
      this.setState({loggedIn: true});
      this.setState({userPrivilege: getJwtPrivilege()});
    }).catch(_ => {})
  }

  handleSectionSelected(section) {
    this.setState({sectionSelected: section})
  }

  handleLogin() {
    this.setState({loggedIn: true})
  }

  render() {
    const { loggedIn, sectionSelected, userPrivilege } = this.state

    if (!loggedIn) {
      return <Login handleLogin={this.handleLogin}/>
    }

    return (
      <div id="app">
        <Header
          sectionSelected={sectionSelected}
          handleSectionSelected={this.handleSectionSelected}
          userPrivilege={userPrivilege}
        />
        <Content
          sectionSelected={sectionSelected}
          // ref={element => this.content = element}
          // onProjectSelected={this.handleProjectSelected}
          // iProjectSelected={this.state.iProjectSelected}
        />
      </div>
    )
  }
}

export default App;
