import './pm2.css';

function Pm2() {
  return (
    <div className="pm2">
    </div>
  );
}

export default Pm2;
