import HttpRequest from '../helpers/HttpRequest.js'

/**
 * login1 endpoint
 * @param {string} authOrigin protocol:hostname:port/pathname to auth service
 * @param {string} username login username
 * @returns {Promise}
 */
export function login1(authOrigin, username) {
  return HttpRequest('POST', `${authOrigin}/login1`, { name: username })
}

/**
 * login2 endpoint
 * @param {string} authOrigin protocol:hostname:port/pathname to auth service
 * @param {string} username login username
 * @param {string} hmac login challenge response as HMAC
 * @returns {Promise}
 */
export function login2(authOrigin, username, hmac) {
  return HttpRequest('POST', `${authOrigin}/login2`, { name: username, hmac })
}

/**
 * validate endpoint
 * @param {string} authOrigin protocol:hostname:port/pathname to auth service
 * @returns {Promise}
 */
export function validate(authOrigin) {
  return HttpRequest('GET', `${authOrigin}/validate`)
}

/**
 * invalidate endpoint
 * @returns {Promise}
 */
export function invalidate() {
  browser.cookies.remove({ name: 'jwt' })
}
