
import { LOG_REQ_LIMIT } from './constants'
import HttpRequest from './HttpRequest'
import Config from '../config.js';

var domain = Config.url.auth
if (window.location.host.substring(0, window.location.host.indexOf(":")) === "admin.localharxer.com") {
	domain =  "http://admin.localharxer.com:8080";
}
export function logs(page = 0) {
  return HttpRequest('GET', `${domain}/api/admin/logs?limit=${LOG_REQ_LIMIT}&skip=${LOG_REQ_LIMIT * page}`)
}
