import './content.css'
import React from 'react';
import { CONTENT_DISPLAYS } from '../../javascript/constants';

import Pm2 from './pm2/Pm2.js'
import Logs from './logs/Logs.js'
import Token from './token/Token.js'

export default class Content extends React.Component {
  // constructor(props) {
  //   super(props)

  //   this.state = {     
  //     contentDisplayed: CONTENT_DISPLAYS.PM2
  //   }
  // }

  selectedContentDisplay() {
    let { sectionSelected } = this.props

    if (sectionSelected === CONTENT_DISPLAYS.PM2) {
      return <Pm2></Pm2>
    } else
    if (sectionSelected === CONTENT_DISPLAYS.LOGS) {
      return <Logs></Logs>
    } else
    if (sectionSelected === CONTENT_DISPLAYS.TOKEN) {
      return <Token></Token>
    }
    
    //default
    return <Pm2></Pm2>
  }

  render() {
    return (
      <div className="content">
        {this.selectedContentDisplay()}
      </div>
    );
  }
}
