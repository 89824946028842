import './token.css';

function Token() {
  return (
    <div className="token">
    </div>
  );
}

export default Token;
